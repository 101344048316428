import { render, staticRenderFns } from "./StockOutOrderModal.vue?vue&type=template&id=5996ef1c&scoped=true&"
import script from "./StockOutOrderModal.vue?vue&type=script&lang=js&"
export * from "./StockOutOrderModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5996ef1c",
  null
  
)

export default component.exports